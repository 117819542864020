import { render, staticRenderFns } from "./Depts.vue?vue&type=template&id=18657a64&scoped=true&"
import script from "./Depts.vue?vue&type=script&lang=js&"
export * from "./Depts.vue?vue&type=script&lang=js&"
import style0 from "./Depts.vue?vue&type=style&index=0&id=18657a64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18657a64",
  null
  
)

export default component.exports